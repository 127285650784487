import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Modal, Spin, Table, Image } from "antd";
import UilEye from "@iconscout/react-unicons/icons/uil-eye";
// import UilEdit from "@iconscout/react-unicons/icons/uil-edit";
import UilTrashAlt from "@iconscout/react-unicons/icons/uil-trash-alt";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { PageHeader } from "../../components/page-headers/page-headers";
// import Heading from "../../components/heading/heading";
// import { AutoComplete } from "../../components/autoComplete/autoComplete";
import { axiosDataSearch } from "../../redux/crud/axios/actionCreator";
import { GlobalUtilityStyle, PaginationStyle } from "../styled";
// import UilPlus from "@iconscout/react-unicons/icons/uil-plus";
import {
  fetchOrdersByStatus,
  deleteOrder,
  fetchSingleOrderByStatus,
} from "../../redux/orders/actionCreator";

function ReturnedOrders() {
  const dispatch = useDispatch();
  const [modal, contextHolder] = Modal.useModal();

  const [orderModal, setOrderModal] = useState({
    selectedRowKeys: 0,
    selectedRows: 0,
    open: false,
    editVisible: false,
    modalType: "primary",
    url: null,
    update: {},
  });

  useEffect(() => {
    if (dispatch) {
      dispatch(fetchOrdersByStatus("returned"));
    }
  }, [dispatch]);

  const { orderList, isLoading, singleOrder } = useSelector((state) => {
    return {
      orderList: state.ordersByStatus.data,
      isLoading: state.ordersByStatus.loading,
      singleOrder: state.singleOrderByStatus.data,
    };
  });
  const usersTableData = [];

  const handleDelete = (id) => {
    modal.confirm({
      title: "Do you Want to delete these?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      centered: "true",
      onOk: () => {
        dispatch(deleteOrder(id)).then((r) => {
          handleCancel();
          dispatch(fetchOrdersByStatus("returned"));
        });
      },
      onCancel: () => {
        console.log("Cancel");
      },
    });
  };

  const showModal = (id) => {
    dispatch(fetchSingleOrderByStatus(id, "returned"));
    setOrderModal({
      ...orderModal,
      open: true,
    });
  };

  const onCancel = () => {
    setOrderModal({
      ...orderModal,
      open: false,
      editVisible: false,
    });
  };

  const handleCancel = () => {
    onCancel();
  };

  if (orderList.length)
    orderList.map((order) => {
      console.log(order);
      const {
        id,
        first_name,
        last_name,
        district,
        payment_method,
        status,
        updated_at,
        order_total,
      } = order;

      let formattedDate = "N/A";
      if (updated_at) {
        const datePart = updated_at.split("T")[0];
        const [year, month, day] = datePart.split("-");
        formattedDate = `${month}/${day}/${year}`;
      }
      return usersTableData.push({
        key: id,
        name: (
          <span className="text-body dark:text-white60 text-[15px] font-medium">
            {first_name} {last_name}
          </span>
        ),
        district: (
          <span className="text-body dark:text-white60 text-[15px] font-medium">
            {district}
          </span>
        ),

        payment_method: (
          <span className="text-body dark:text-white60 text-[15px] font-medium">
            {payment_method}
          </span>
        ),
        last_updated: (
          <span className="text-body dark:text-white60 text-[15px] font-medium">
            {formattedDate}
          </span>
        ),
        order_total: (
          <span className="text-body dark:text-white60 text-[15px] font-medium">
            {order_total}
          </span>
        ),
        status: (
          <span className="text-body dark:text-white60 text-[15px] font-medium">
            {status}
          </span>
        ),
        action: (
          <div className="min-w-[150px] text-end -m-2">
            <Link className="inline-block m-2" to="#">
              <UilEye
                className="w-4 text-light-extra dark:text-white60"
                onClick={() => showModal(id)}
              />
            </Link>
            {/* <Link className="inline-block m-2" to={`/product/${id}`}>
              <UilEdit className="w-4 text-light-extra dark:text-white60" />
            </Link> */}
            <Link className="inline-block m-2" to="#">
              <UilTrashAlt
                className="w-4 text-light-extra dark:text-white60"
                onClick={() => handleDelete(id)}
              />
            </Link>
          </div>
        ),
      });
    });

  const usersTableColumns = [
    {
      title: "Id",
      dataIndex: "key",
      key: "id",
      width: 20,
    },
    {
      title: "Customer",
      dataIndex: "name",
      key: "name",
      width: 100,
      ellipsis: true,
    },
    {
      title: "District",
      dataIndex: "district",
      key: "district",
      width: 70,
    },

    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "payment_method",
      width: 70,
    },
    {
      title: "Last Updated",
      dataIndex: "last_updated",
      key: "last_updated",
      width: 70,
    },
    {
      title: "Total",
      dataIndex: "order_total",
      key: "order_total",
      width: 50,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 50,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 70,
    },
  ];

  const rowSelection = {
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled Category", // Column configuration not to be checked
      name: record.name,
    }),
  };

  const onHandleSearch = (e) => {
    dispatch(axiosDataSearch(e.target.value, orderList));
  };

  const { searchData, users } = useSelector((state) => {
    return {
      searchData: state.headerSearchData,
      users: state.users,
    };
  });

  const [state, setState] = useState({
    notData: searchData,
    selectedRowKeys: 0,
    selectedRows: 0,
  });

  const { notData } = state;

  const handleSearch = (searchText) => {
    const data = searchData.filter((item) =>
      item.title.toUpperCase().startsWith(searchText.toUpperCase())
    );
    setState({
      ...state,
      notData: data,
    });
  };

  let OrderDetailsModal = ({ singleOrder }) => {
    if (singleOrder.length === 1) {
      singleOrder = singleOrder[0];

      const billing = {
        address: singleOrder.address,
        phone: singleOrder.phone,
        secondary_phone: singleOrder.secondary_phone,
        district: singleOrder.district,
      };

      const formatOrderDate = (date) => {
        const datePart = date.split("T")[0];
        const [year, month, day] = datePart.split("-");
        return `${month}/${day}/${year}`;
      };

      const created_at = formatOrderDate(singleOrder.created_at);

      const Items = ({ items }) => {
        return (
          <div>
            {/* Header Row */}
            <div className="grid grid-cols-8 gap-4 font-bold">
              <div className="col-span-2">Product Name</div>
              <div>SKU</div>
              <div>Color</div>
              <div>Size</div>
              <div>Price</div>
              <div>Quantity</div>
              <div>Total</div>
            </div>
            {/* Items Rows */}
            {items.map((item, index) => (
              <div key={index} className="grid grid-cols-8 gap-4">
                <div className="col-span-2">{item.product_name}</div>
                <div>{item.sku}</div>
                <div>{item.color}</div>
                <div>{item.size || "N/A"}</div>
                <div>{item.price}</div>
                <div>{item.quantity}</div>
                <div>{Number(item.price) * Number(item.quantity)}</div>
              </div>
            ))}
          </div>
        );
      };

      const Shipping = ({ shipping }) => {
        return (
          <>
            <div className="text-end">Shipping Address :</div>
            <div className="col-span-2">{shipping.address}</div>
            <div className="text-end">Shipping Phone :</div>
            <div className="col-span-2">{shipping.phone}</div>
            <div className="text-end">Shipping Secondary Phone :</div>
            <div className="col-span-2">{shipping.secondary_phone}</div>
            <div className="text-end">Shipping District :</div>
            <div className="col-span-2">{shipping.district}</div>
          </>
        );
      };

      const Billing = ({ billing }) => {
        return (
          <>
            <div className="text-end">Billing Address :</div>
            <div className="col-span-2">{billing.address}</div>
            <div className="text-end">Billing Phone :</div>
            <div className="col-span-2">{billing.phone}</div>
            <div className="text-end">Billing Secondary Phone :</div>
            <div className="col-span-2">{billing.secondary_phone}</div>
            <div className="text-end">Billing District :</div>
            <div className="col-span-2">{billing.district}</div>
          </>
        );
      };

      return (
        <div className="grid grid-cols-3 gap-4 grid-flow-row">
          <div className="text-end">Customer Name :</div>
          <div className="col-span-2">{`${singleOrder.first_name} ${singleOrder.last_name}`}</div>
          <div className="text-end">Email :</div>
          <div className="col-span-2">{singleOrder.email}</div>
          <div className="text-end">Order Total :</div>
          <div className="col-span-2">{singleOrder.order_total}</div>
          <div className="text-end">Payment Method :</div>
          <div className="col-span-2">{singleOrder.payment_method}</div>
          <div className="text-end">Status :</div>
          <div className="col-span-2">{singleOrder.status}</div>
          <div className="text-end">Order Date :</div>
          <div className="col-span-2">{created_at}</div>

          <div className="col-span-3">
            <h4>Items:</h4>
          </div>
          <div className="col-span-3">
            <Items items={singleOrder.items} />
          </div>

          <div className="col-span-3">
            <h4>Billing Information:</h4>
          </div>
          <Billing billing={billing} />

          {singleOrder.different_address && (
            <>
              <div className="col-span-3">
                <h4>Shipping Information:</h4>
              </div>
              <Shipping shipping={singleOrder.shipping} />
            </>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <PageHeader
        className="pt-[29px] px-[30px] pb-[31px] bg-[#f4f5f7] dark:bg-transparent flex items-start justify-between [&>div]:flex-1 [&>div]:flex [&>div]:items-center min-md:[&>div]:justify-between [&>div]:justify-center md:[&>div]:flex-col [&>div>div>span+span]:mt-0 md:[&>div]:gap-[15px] md:[&>div>div]:flex-warp md:[&>div>div]:flex-col md:[&>div>div]:gap-[15px]"
        backIcon={false}
        title={
          <>
            <span className="text-[22px] font-semibold text-dark dark:text-white87 relative min-md:ltr:pr-[24px] min-md:ltr:mr-[24px] min-md:rtl:pl-[24px] min-md:rtl:ml-[24px] capitalize leading-[32px] after:absolute ltr:after:right-0 rtl:after:left-0 after:top-0 after:h-full after:w-[1px] after:content-[''] after:bg-normal dark:after:bg-white10 md:after:hidden">
              Returned Orders
            </span>
          </>
        }
        subTitle={
          <div className="flex items-center font-medium text-theme-gray dark:text-white60 min-md:gap-[25px] gap-[15px]">
            <div className="flex items-center [&>div>div]:h-[46px] [&>.ant-select>.ant-select-selector>span>input]:!h-[46px] [&>.ant-select>.ant-select-selector>span>input]:bg-transparent [&>div>div]:rounded-[20px] min-lg:[&>div>div]:w-[305px] [&>div>div]:bg-transparent [&>div>div]:border-none [&>div>div]:shadow-none bg-white dark:bg-white10 rounded-[20px]">
              {/*<AutoComplete*/}
              {/*    dropdownMatchSelectWidth={false}*/}
              {/*    onSearch={handleSearch}*/}
              {/*    dataSource={notData}*/}
              {/*    width="100%"*/}
              {/*    placeholder="Search by Name"*/}
              {/*>*/}
              {/*    <Input*/}
              {/*        className="bg-transparent px-5 border border-regular dark:border-white10 shadow-none rounded-[100px] [&>input]:!bg-transparent dark:[&>input]:!bg-transparent h-[38px]"/>*/}
              {/*</AutoComplete>*/}
              {/*<Button*/}
              {/*    className="h-[46px] rounded-tl-none rounded-bl-none bg-transparent border-none shadow-none">*/}
              {/*    <SearchOutlined*/}
              {/*        className="flex text-light-extra dark:text-white87 [&>svg]:text-light-extra dark:[&>svg]:text-white87"/>*/}
              {/*</Button>*/}
            </div>
          </div>
        }
      />
      <div className="min-h-[715px] lg:min-h-[580px] px-8 xl:px-[15px] pb-[30px] bg-transparent hexadash-calendar-wrap">
        <Row gutter={15}>
          <Col md={24}>
            <div className="bg-white dark:bg-white10 p-[25px] rounded-[10px]">
              {isLoading ? (
                <div className="flex items-center justify-center [&>div]:flex [&>div]:items-center">
                  <Spin />
                </div>
              ) : (
                <GlobalUtilityStyle>
                  <PaginationStyle>
                    <div className="ant-pagination-custom-style table-responsive hover-tr-none table-th-shape-none table-last-th-text-right table-th-border-none table-head-rounded table-selection-col-pl-25 table-tr-selected-background-transparent table-td-border-none bg-white dark:bg-transparent rounded-[10px] ltr:[&>div>div>div>div>div>.ant-table-content>table>thead>tr>th:first-child]:rounded-l-10 rtl:[&>div>div>div>div>div>.ant-table-content>table>thead>tr>th:first-child]:rounded-r-10 rtl:[&>div>div>div>div>div>.ant-table-content>table>thead>tr>th:first-child]:rounded-none ltr:[&>div>div>div>div>div>.ant-table-content>table>thead>tr>th:last-child]:rounded-r-10 rtl:[&>div>div>div>div>div>.ant-table-content>table>thead>tr>th:last-child]:rounded-l-10 rtl:[&>div>div>div>div>div>.ant-table-content>table>thead>tr>th:last-child]:rounded-none">
                      <Table
                        className="[&>div>div>.ant-table]:mb-7 [&>div>div>.ant-table]:pb-5 [&>div>div>.ant-table]:border-b [&>div>div>.ant-table]:border-regular dark:[&>div>div>.ant-table]:border-white10 ltr:[&>div>div>div>div>div>table>thead>tr>th:first-child]:pl-[20px] ltr:[&>div>div>div>div>div>table>tbody>tr>td:first-child]:pl-[20px] rtl:[&>div>div>div>div>div>table>thead>tr>th:first-child]:pr-[20px] rtl:[&>div>div>div>div>div>table>tbody>tr>td:first-child]:pr-[20px]"
                        dataSource={usersTableData}
                        columns={usersTableColumns}
                        pagination={{
                          defaultPageSize: 10,
                          total: usersTableData.length,
                          showTotal: (total, range) =>
                            `${range[0]}-${range[1]} of ${total} items`,
                          className:
                            "[&>.ant-pagination-total-text]:border-none ssm:[&>.ant-pagination-total-text]:w-full [&>.ant-pagination-total-text]:text-center [&>.ant-pagination-total-text]:text-dark dark:[&>.ant-pagination-total-text]:text-white87 [&>.ant-pagination-total-text]:font-medium [&>.ant-pagination-total-text]:text-[17px md:justify-center",
                        }}
                      />
                    </div>
                  </PaginationStyle>
                </GlobalUtilityStyle>
              )}
            </div>
          </Col>
        </Row>
      </div>
      {contextHolder}
      <Modal
        type={orderModal.modalType}
        title="Order Details"
        open={orderModal.open}
        footer={null}
        centered
        onCancel={handleCancel}
        width={800}
      >
        <div className="px-1.5">
          <OrderDetailsModal singleOrder={singleOrder} />
        </div>
      </Modal>
    </>
  );
}

export default ReturnedOrders;
