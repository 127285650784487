const actions = {
  SINGLE_PRODUCT_BEGIN: "SINGLE_PRODUCT_BEGIN",
  SINGLE_PRODUCT_SUCCESS: "SINGLE_PRODUCT_SUCCESS",
  SINGLE_PRODUCT_ERR: "SINGLE_PRODUCT_ERR",

  FILTER_PRODUCT_BEGIN: "FILTER_PRODUCT_BEGIN",
  FILTER_PRODUCT_SUCCESS: "FILTER_PRODUCT_SUCCESS",
  FILTER_PRODUCT_ERR: "FILTER_PRODUCT_ERR",

  SORTING_PRODUCT_BEGIN: "SORTING_PRODUCT_BEGIN",
  SORTING_PRODUCT_SUCCESS: "SORTING_PRODUCT_SUCCESS",
  SORTING_PRODUCT_ERR: "SORTING_PRODUCT_ERR",

  ADD_PRODUCT_BEGIN: "ADD_PRODUCT_BEGIN",
  ADD_PRODUCT_SUCCESS: "ADD_PRODUCT_SUCCESS",
  ADD_PRODUCT_ERR: "ADD_PRODUCT_ERR",

  FETCHING_PRODUCT_BEGIN: "FETCHING_PRODUCT_BEGIN",
  FETCHING_PRODUCT_SUCCESS: "FETCHING_PRODUCT_SUCCESS",
  FETCHING_PRODUCT_ERR: "FETCHING_PRODUCT_ERR",

  FETCHING_SINGLE_PRODUCT_BEGIN: "FETCHING_SINGLE_PRODUCT_BEGIN",
  FETCHING_SINGLE_PRODUCT_SUCCESS: "FETCHING_SINGLE_PRODUCT_SUCCESS",
  FETCHING_SINGLE_PRODUCT_ERR: "FETCHING_SINGLE_PRODUCT_ERR",

  DELETING_PRODUCT_BEGIN: "DELETING_PRODUCT_BEGIN",
  DELETING_PRODUCT_SUCCESS: "DELETING_PRODUCT_SUCCESS",
  DELETING_PRODUCT_ERR: "DELETING_PRODUCT_ERR",

  UPDATING_PRODUCT_BEGIN: "UPDATING_PRODUCT_BEGIN",
  UPDATING_PRODUCT_SUCCESS: "UPDATING_PRODUCT_SUCCESS",
  UPDATING_PRODUCT_ERR: "UPDATING_PRODUCT_ERR",

  singleProductBegin: () => {
    return {
      type: actions.SINGLE_PRODUCT_BEGIN,
    };
  },

  singleProductSuccess: (data) => {
    return {
      type: actions.SINGLE_PRODUCT_SUCCESS,
      data,
    };
  },

  singleProductErr: (err) => {
    return {
      type: actions.SINGLE_PRODUCT_ERR,
      err,
    };
  },

  filterProductBegin: () => {
    return {
      type: actions.FILTER_PRODUCT_BEGIN,
    };
  },

  filterProductSuccess: (data) => {
    return {
      type: actions.FILTER_PRODUCT_SUCCESS,
      data,
    };
  },

  filterProductErr: (err) => {
    return {
      type: actions.FILTER_PRODUCT_ERR,
      err,
    };
  },

  sortingProductBegin: () => {
    return {
      type: actions.SORTING_PRODUCT_BEGIN,
    };
  },

  sortingProductSuccess: (data) => {
    return {
      type: actions.SORTING_PRODUCT_SUCCESS,
      data,
    };
  },

  sortingProductErr: (err) => {
    return {
      type: actions.SORTING_PRODUCT_ERR,
      err,
    };
  },

  addingProductBegin: () => {
    return {
      type: actions.ADD_PRODUCT_BEGIN,
    };
  },

  addingProductSuccess: (data) => {
    return {
      type: actions.ADD_PRODUCT_SUCCESS,
      data,
    };
  },

  addingProductErr: (err) => {
    return {
      type: actions.ADD_PRODUCT_ERR,
      err,
    };
  },
  fetchingProductBegin: () => {
    return {
      type: actions.FETCHING_PRODUCT_BEGIN,
    };
  },

  fetchingProductSuccess: (data) => {
    return {
      type: actions.FETCHING_PRODUCT_SUCCESS,
      data,
    };
  },

  fetchingProductErr: (err) => {
    return {
      type: actions.FETCHING_PRODUCT_ERR,
      err,
    };
  },

  fetchingSingleProductBegin: () => {
    return {
      type: actions.FETCHING_SINGLE_PRODUCT_BEGIN,
    };
  },

  fetchingSingleProductSuccess: (data) => {
    return {
      type: actions.FETCHING_SINGLE_PRODUCT_SUCCESS,
      data,
    };
  },

  fetchingSingleProductErr: (err) => {
    return {
      type: actions.FETCHING_SINGLE_PRODUCT_ERR,
      err,
    };
  },

  deleteProductBegin: () => {
    return {
      type: actions.DELETING_PRODUCT_BEGIN,
    };
  },

  deleteProductSuccess: (data) => {
    return {
      type: actions.DELETING_PRODUCT_SUCCESS,
      data,
    };
  },

  deleteProductErr: (err) => {
    return {
      type: actions.DELETING_PRODUCT_ERR,
      err,
    };
  },

  updateProductBegin: () => {
    return {
      type: actions.UPDATING_PRODUCT_BEGIN,
    };
  },

  updateProductSuccess: (data) => {
    return {
      type: actions.UPDATING_PRODUCT_SUCCESS,
      data,
    };
  },

  updateProductErr: (err) => {
    return {
      type: actions.UPDATING_PRODUCT_ERR,
      err,
    };
  },
};

export default actions;
