import actions from "./actions";
import initialState from "../../demoData/orders.json";
import { DataService } from "../../config/dataService/dataService";
import { notification } from "antd";

const NotificationSuccess = (m) => {
  notification.success({
    message: m,
  });
};

const NotificationError = (m) => {
  notification.error({
    message: m,
  });
};

const {
  filterOrderBegin,
  filterOrderSuccess,
  filterOrderErr,
  fetchingOrderBegin,
  fetchingOrderSuccess,
  fetchingOrderErr,
  fetchingOrderByStatusBegin,
  fetchingOrderByStatusSuccess,
  fetchingOrderByStatusErr,
  fetchingSingleOrderBegin,
  fetchingSingleOrderSuccess,
  fetchingSingleOrderErr,
  fetchingSingleOrderByStatusBegin,
  fetchingSingleOrderByStatusSuccess,
  fetchingSingleOrderByStatusErr,
  updatingOrderBegin,
  updatingOrderSuccess,
  updatingOrderErr,
  deletingOrderBegin,
  deletingOrderSuccess,
  deletingOrderErr,
  updatingOrderStatusBegin,
  updatingOrderStatusSuccess,
  updatingOrderStatusErr,
} = actions;

const orderFilter = (column, value) => {
  return async (dispatch) => {
    try {
      dispatch(filterOrderBegin());

      const data = initialState.filter((item) => {
        if (value !== "") {
          return item[column] === value;
        }
        return item;
      });

      dispatch(filterOrderSuccess(data));
    } catch (err) {
      dispatch(filterOrderErr(err));
    }
  };
};

const fetchOrders = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchingOrderBegin());
      const response = await DataService.get("/orders");
      if (response.status !== 200) {
        dispatch(fetchingOrderErr("Order fetching failed!"));
      } else {
        dispatch(fetchingOrderSuccess(response.data));
      }
    } catch (err) {
      dispatch(fetchingOrderErr(err));
    }
  };
};

const fetchOrdersByStatus = (status) => {
  return async (dispatch) => {
    try {
      dispatch(fetchingOrderByStatusBegin);
      const response = await DataService.get(`/ordersStatus/${status}`);
      if (response.status !== 200) {
        dispatch(fetchingOrderByStatusErr("Order fetching failed!"));
      } else {
        dispatch(fetchingOrderByStatusSuccess(response.data));
      }
    } catch (err) {
      dispatch(fetchingOrderByStatusErr(err));
    }
  };
};

const fetchSingleOrder = (id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchingSingleOrderBegin());
      const response = await DataService.get(`/orders/${id}`);
      if (response.status !== 200) {
        dispatch(fetchingSingleOrderErr("Order fetching failed!"));
      } else {
        dispatch(fetchingSingleOrderSuccess(response.data));
      }
    } catch (err) {
      dispatch(fetchingSingleOrderErr(err));
    }
  };
};

const fetchSingleOrderByStatus = (id, status) => {
  return async (dispatch) => {
    try {
      dispatch(fetchingSingleOrderByStatusBegin());
      const response = await DataService.get(`/ordersStatus/${id}/${status}`);
      if (response.status !== 200) {
        dispatch(fetchingSingleOrderByStatusErr("Order fetching failed!"));
      } else {
        dispatch(fetchingSingleOrderByStatusSuccess(response.data));
      }
    } catch (err) {
      dispatch(fetchingSingleOrderByStatusErr(err));
    }
  };
};

const updateOrder = (id, data) => {
  return async (dispatch) => {
    dispatch(updatingOrderBegin());
    try {
      const response = await DataService.patch(`/orders/${id}`, data);
      if (response.status !== 200) {
        dispatch(updatingOrderErr(response.data.message));
      } else {
        dispatch(updatingOrderSuccess());
        NotificationSuccess("Order Updated Successfully");
      }
    } catch (err) {
      dispatch(updatingOrderErr(err));
      NotificationError("Update Failed");
    }
  };
};

const deleteOrder = (id) => {
  return async (dispatch) => {
    dispatch(deletingOrderBegin());
    try {
      const response = await DataService.delete(`/orders/${id}`);
      if (response.status !== 200) {
        dispatch(deletingOrderErr(response.data.message));
      } else {
        dispatch(deletingOrderSuccess());
        NotificationSuccess("Order Deleted Successfully");
      }
    } catch (err) {
      dispatch(deletingOrderErr(err));
      NotificationError("Delete Failed");
    }
  };
};

const updateOrderStatus = (id, status) => {
  return async (dispatch) => {
    dispatch(updatingOrderStatusBegin());
    try {
      const response = await DataService.patch(
        `/updateOrderStatus/${id}/${status}`
      );
      if (response.status !== 200) {
        dispatch(updatingOrderStatusErr(response.data.message));
      } else {
        dispatch(updatingOrderStatusSuccess());
        NotificationSuccess("Order Status Updated Successfully");
      }
    } catch (err) {
      dispatch(updatingOrderStatusErr(err));
      NotificationError("Update Failed");
    }
  };
};

export {
  orderFilter,
  fetchOrders,
  fetchOrdersByStatus,
  fetchSingleOrder,
  fetchSingleOrderByStatus,
  updateOrder,
  deleteOrder,
  updateOrderStatus,
};
