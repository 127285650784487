const actions = {
  FILTER_ORDER_BEGIN: "FILTER_ORDER_BEGIN",
  FILTER_ORDER_SUCCESS: "FILTER_ORDER_SUCCESS",
  FILTER_ORDER_ERR: "FILTER_ORDER_ERR",

  FETCHING_ORDER_BEGIN: "FETCHING_ORDER_BEGIN",
  FETCHING_ORDER_SUCCESS: "FETCHING_ORDER_SUCCESS",
  FETCHING_ORDER_ERR: "FETCHING_ORDER_ERR",

  FETCHING_ORDER_BY_STATUS_BEGIN: "FETCHING_ORDER_BY_STATUS_BEGIN",
  FETCHING_ORDER_BY_STATUS_SUCCESS: "FETCHING_ORDER_BY_STATUS_SUCCESS",
  FETCHING_ORDER_BY_STATUS_ERR: "FETCHING_ORDER_BY_STATUS_ERR",

  FETCHING_SINGLE_ORDER_BEGIN: "FETCHING_SINGLE_ORDER_BEGIN",
  FETCHING_SINGLE_ORDER_SUCCESS: "FETCHING_SINGLE_ORDER_SUCCESS",
  FETCHING_SINGLE_ORDER_ERR: "FETCHING_SINGLE_ORDER_ERR",

  FETCHING_SINGLE_ORDER_BY_STATUS_BEGIN:
    "FETCHING_SINGLE_ORDER_BY_STATUS_BEGIN",
  FETCHING_SINGLE_ORDER_BY_STATUS_SUCCESS:
    "FETCHING_SINGLE_ORDER_BY_STATUS_SUCCESS",
  FETCHING_SINGLE_ORDER_BY_STATUS_ERR: "FETCHING_SINGLE_ORDER_BY_STATUS_ERR",

  UPDATING_ORDER_BEGIN: "UPDATING_ORDER_BEGIN",
  UPDATING_ORDER_SUCCESS: "UPDATING_ORDER_SUCCESS",
  UPDATING_ORDER_ERR: "UPDATING_ORDER_ERR",

  DELETING_ORDER_BEGIN: "DELETING_ORDER_BEGIN",
  DELETING_ORDER_SUCCESS: "DELETING_ORDER_SUCCESS",
  DELETING_ORDER_ERR: "DELETING_ORDER_ERR",

  UPDATING_ORDER_STATUS_BEGIN: "UPDATING_ORDER_STATUS_BEGIN",
  UPDATING_ORDER_STATUS_SUCCESS: "UPDATING_ORDER_STATUS_SUCCESS",
  UPDATING_ORDER_STATUS_ERR: "UPDATING_ORDER_STATUS_ERR",

  filterOrderBegin: () => {
    return {
      type: actions.FILTER_ORDER_BEGIN,
    };
  },

  filterOrderSuccess: (data) => {
    return {
      type: actions.FILTER_ORDER_SUCCESS,
      data,
    };
  },

  filterOrderErr: (err) => {
    return {
      type: actions.FILTER_ORDER_ERR,
      err,
    };
  },

  fetchingOrderBegin: () => {
    return {
      type: actions.FETCHING_ORDER_BEGIN,
    };
  },

  fetchingOrderSuccess: (data) => {
    return {
      type: actions.FETCHING_ORDER_SUCCESS,
      data,
    };
  },

  fetchingOrderErr: (err) => {
    return {
      type: actions.FETCHING_ORDER_ERR,
      err,
    };
  },

  fetchingOrderByStatusBegin: () => {
    return {
      type: actions.FETCHING_ORDER_BY_STATUS_BEGIN,
    };
  },

  fetchingOrderByStatusSuccess: (data) => {
    return {
      type: actions.FETCHING_ORDER_BY_STATUS_SUCCESS,
      data,
    };
  },

  fetchingOrderByStatusErr: (err) => {
    return {
      type: actions.FETCHING_ORDER_BY_STATUS_ERR,
      err,
    };
  },

  fetchingSingleOrderBegin: () => {
    return {
      type: actions.FETCHING_SINGLE_ORDER_BEGIN,
    };
  },

  fetchingSingleOrderSuccess: (data) => {
    return {
      type: actions.FETCHING_SINGLE_ORDER_SUCCESS,
      data,
    };
  },

  fetchingSingleOrderErr: (err) => {
    return {
      type: actions.FETCHING_SINGLE_ORDER_ERR,
      err,
    };
  },

  fetchingSingleOrderByStatusBegin: () => {
    return {
      type: actions.FETCHING_SINGLE_ORDER_BY_STATUS_BEGIN,
    };
  },

  fetchingSingleOrderByStatusSuccess: (data) => {
    return {
      type: actions.FETCHING_SINGLE_ORDER_BY_STATUS_SUCCESS,
      data,
    };
  },

  fetchingSingleOrderByStatusErr: (err) => {
    return {
      type: actions.FETCHING_SINGLE_ORDER_BY_STATUS_ERR,
      err,
    };
  },

  updatingOrderBegin: () => {
    return {
      type: actions.UPDATING_ORDER_BEGIN,
    };
  },

  updatingOrderSuccess: (data) => {
    return {
      type: actions.UPDATING_ORDER_SUCCESS,
      data,
    };
  },

  updatingOrderErr: (err) => {
    return {
      type: actions.UPDATING_ORDER_ERR,
      err,
    };
  },

  deletingOrderBegin: () => {
    return {
      type: actions.DELETING_ORDER_BEGIN,
    };
  },

  deletingOrderSuccess: (data) => {
    return {
      type: actions.DELETING_ORDER_SUCCESS,
      data,
    };
  },

  deletingOrderErr: (err) => {
    return {
      type: actions.DELETING_ORDER_ERR,
      err,
    };
  },

  updatingOrderStatusBegin: () => {
    return {
      type: actions.UPDATING_ORDER_STATUS_BEGIN,
    };
  },

  updatingOrderStatusSuccess: (data) => {
    return {
      type: actions.UPDATING_ORDER_STATUS_SUCCESS,
      data,
    };
  },

  updatingOrderStatusErr: (err) => {
    return {
      type: actions.UPDATING_ORDER_STATUS_ERR,
      err,
    };
  },
};

export default actions;
