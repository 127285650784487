import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import PendingOrders from "../../container/product/PendingOrders";
import FailedOrders from "../../container/product/FailedOrders";
import ShippedOrders from "../../container/product/ShippedOrders";
import ReturnedOrders from "../../container/product/ReturnedOrders";

const Category = lazy(() => import("../../container/product/Category"));
const SubCategory = lazy(() => import("../../container/product/SubCategory"));
const SubCategoryElements = lazy(() =>
  import("../../container/product/SubCategoryElements")
);
const AddSubCategoryElements = lazy(() =>
  import("../../container/product/AddSubCategoryElements")
);
const EditSubCategoryElements = lazy(() =>
  import("../../container/product/EditSubCategoryElements")
);
const Product = lazy(() => import("../../container/product/Product"));
const AddProduct = lazy(() => import("../../container/product/AddProduct"));
const EditProduct = lazy(() => import("../../container/product/EditProduct"));
// const DataTable = lazy(() => import("../../container/users/UserListDataTable"));
const Team = lazy(() => import("../../container/users/Team"));
const NotFound = lazy(() => import("../../container/pages/404"));
const Orders = lazy(() => import("../../container/product/Orders"));
const CompleteOrders = lazy(() =>
  import("../../container/product/CompleteOrders")
);

function ProductRoute() {
  return (
    <Routes>
      <Route path="category" element={<Category />} />
      <Route path="subcategory" element={<SubCategory />} />
      <Route path="subCategoryElements" element={<SubCategoryElements />} />
      <Route
        path="subCategoryElements/new"
        element={<AddSubCategoryElements />}
      />
      <Route
        path="subCategoryElements/edit/:id"
        element={<EditSubCategoryElements />}
      />
      <Route path="orders" element={<Orders />} />
      <Route path="completeOrders" element={<CompleteOrders />} />
      <Route path="pendingOrders" element={<PendingOrders />} />
      <Route path="failedOrders" element={<FailedOrders />} />
      <Route path="shippedOrders" element={<ShippedOrders />} />
      <Route path="returnedOrders" element={<ReturnedOrders />} />

      <Route path="/" element={<Product />} />
      <Route path="new" element={<AddProduct />} />
      <Route path="/:id" element={<EditProduct />} />

      <Route path="team" element={<Team />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default ProductRoute;
