import actions from './actions';

const {
    ADD_CATEGORY_BEGIN,
    ADD_CATEGORY_SUCCESS,
    ADD_CATEGORY_ERR,

    ALL_CATEGORY_BEGIN,
    ALL_CATEGORY_SUCCESS,
    ALL_CATEGORY_ERR,

    UPDATE_CATEGORY_BEGIN,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_ERR,

    DELETE_CATEGORY_BEGIN,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_ERR,

} = actions;

const initialState = {
    data: [],
    loading: false,
    error: null,
};

const CategoryReducer = (state = initialState, action) => {
    const {type, data, err} = action;
    switch (type) {

        case ADD_CATEGORY_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ADD_CATEGORY_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case ADD_CATEGORY_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case ALL_CATEGORY_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ALL_CATEGORY_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case ALL_CATEGORY_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case UPDATE_CATEGORY_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case UPDATE_CATEGORY_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case UPDATE_CATEGORY_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case DELETE_CATEGORY_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case DELETE_CATEGORY_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case DELETE_CATEGORY_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

export {
    CategoryReducer
};
