import actions from "./actions";
import staticData from "../../demoData/orders.json";

const initialState = {
  data: staticData,
  loading: false,
  error: null,
};

const {
  FILTER_ORDER_BEGIN,
  FILTER_ORDER_SUCCESS,
  FILTER_ORDER_ERR,
  FETCHING_ORDER_BEGIN,
  FETCHING_ORDER_SUCCESS,
  FETCHING_ORDER_ERR,
  FETCHING_ORDER_BY_STATUS_BEGIN,
  FETCHING_ORDER_BY_STATUS_SUCCESS,
  FETCHING_ORDER_BY_STATUS_ERR,
  FETCHING_SINGLE_ORDER_BEGIN,
  FETCHING_SINGLE_ORDER_SUCCESS,
  FETCHING_SINGLE_ORDER_ERR,
  FETCHING_SINGLE_ORDER_BY_STATUS_BEGIN,
  FETCHING_SINGLE_ORDER_BY_STATUS_SUCCESS,
  FETCHING_SINGLE_ORDER_BY_STATUS_ERR,
  UPDATING_ORDER_BEGIN,
  UPDATING_ORDER_SUCCESS,
  UPDATING_ORDER_ERR,
  DELETING_ORDER_BEGIN,
  DELETING_ORDER_SUCCESS,
  DELETING_ORDER_ERR,
  COMPLETING_ORDER_BEGIN,
  COMPLETING_ORDER_SUCCESS,
  COMPLETING_ORDER_ERR,
} = actions;

const ordersReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FILTER_ORDER_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case FILTER_ORDER_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case FILTER_ORDER_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    case FETCHING_ORDER_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case FETCHING_ORDER_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case FETCHING_ORDER_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    case UPDATING_ORDER_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case UPDATING_ORDER_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case UPDATING_ORDER_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    case DELETING_ORDER_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case DELETING_ORDER_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case DELETING_ORDER_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const singleOrderReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FETCHING_SINGLE_ORDER_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case FETCHING_SINGLE_ORDER_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case FETCHING_SINGLE_ORDER_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    case COMPLETING_ORDER_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case COMPLETING_ORDER_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case COMPLETING_ORDER_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const ordersByStatusReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FETCHING_ORDER_BY_STATUS_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case FETCHING_ORDER_BY_STATUS_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case FETCHING_ORDER_BY_STATUS_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const singleOrderByStatusReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FETCHING_SINGLE_ORDER_BY_STATUS_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case FETCHING_SINGLE_ORDER_BY_STATUS_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case FETCHING_SINGLE_ORDER_BY_STATUS_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export {
  ordersReducer,
  singleOrderReducer,
  ordersByStatusReducer,
  singleOrderByStatusReducer,
};
