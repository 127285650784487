// import { createStore, applyMiddleware, compose } from 'redux';
import { createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './rootReducers';

// (process.env.NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose
// const store = createStore(rootReducer, compose(applyMiddleware(withExtraArgument())));
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk.withExtraArgument())));
export default store;
