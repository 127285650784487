const actions = {

    ADD_SUBCATEGORY_ELEMENTS_BEGIN: 'ADD_SUBCATEGORY_ELEMENTS_BEGIN',
    ADD_SUBCATEGORY_ELEMENTS_SUCCESS: 'ADD_SUBCATEGORY_ELEMENTS_SUCCESS',
    ADD_SUBCATEGORY_ELEMENTS_ERR: 'ADD_SUBCATEGORY_ELEMENTS_ERR',

    ALL_SUBCATEGORY_ELEMENTS_BEGIN: 'ALL_SUBCATEGORY_ELEMENTS_BEGIN',
    ALL_SUBCATEGORY_ELEMENTS_SUCCESS: 'ALL_SUBCATEGORY_ELEMENTS_SUCCESS',
    ALL_SUBCATEGORY_ELEMENTS_ERR: 'ALL_SUBCATEGORY_ELEMENTS_ERR',

    GET_SUBCATEGORY_ELEMENTS_BEGIN: ' GET_SUBCATEGORY_ELEMENTS_BEGIN',
    GET_SUBCATEGORY_ELEMENTS_SUCCESS: ' GET_SUBCATEGORY_ELEMENTS_SUCCESS',
    GET_SUBCATEGORY_ELEMENTS_ERR: ' GET_SUBCATEGORY_ELEMENTS_ERR',

    GET_SUBCATEGORY_ELEMENTS_BY_ID_BEGIN: ' GET_SUBCATEGORY_ELEMENTS_BY_ID_BEGIN',
    GET_SUBCATEGORY_ELEMENTS_BY_ID_SUCCESS: ' GET_SUBCATEGORY_ELEMENTS_BY_ID_SUCCESS',
    GET_SUBCATEGORY_ELEMENTS_BY_ID_ERR: ' GET_SUBCATEGORY_ELEMENTS_ERR',

    UPDATE_SUBCATEGORY_ELEMENTS_BEGIN: 'UPDATE_SUBCATEGORY_ELEMENTS_BEGIN',
    UPDATE_SUBCATEGORY_ELEMENTS_SUCCESS: 'UPDATE_SUBCATEGORY_ELEMENTS_SUCCESS',
    UPDATE_SUBCATEGORY_ELEMENTS_ERR: 'UPDATE_SUBCATEGORY_ELEMENTS_ERR',

    DELETE_SUBCATEGORY_ELEMENTS_BEGIN: 'DELETE_SUBCATEGORY_ELEMENTS_BEGIN',
    DELETE_SUBCATEGORY_ELEMENTS_SUCCESS: 'DELETE_SUBCATEGORY_ELEMENTS_SUCCESS',
    DELETE_SUBCATEGORY_ELEMENTS_ERR: 'DELETE_SUBCATEGORY_ELEMENTS_ERR',


    addSubCategoryElementBegin: () => {
        return {
            type: actions.ADD_SUBCATEGORY_ELEMENTS_BEGIN,
        };
    },

    addSubCategoryElementSuccess: () => {
        return {
            type: actions.ADD_SUBCATEGORY_ELEMENTS_SUCCESS,
        };
    },

    addSubCategoryElementErr: (err) => {
        return {
            type: actions.ADD_SUBCATEGORY_ELEMENTS_ERR,
            payload: err,
        };
    },

    allSubCategoryElementBegin: () => {
        return {
            type: actions.ALL_SUBCATEGORY_ELEMENTS_BEGIN,
        };
    },

    allSubCategoryElementSuccess: (data) => {
        return {
            type: actions.ALL_SUBCATEGORY_ELEMENTS_SUCCESS,
            data,
        };
    },

    allSubCategoryElementErr: (err) => {
        return {
            type: actions.ALL_SUBCATEGORY_ELEMENTS_ERR,
            err,
        };
    },

    getSubCategoryElementBegin: () => {
        return {
            type: actions.GET_SUBCATEGORY_ELEMENTS_BEGIN,
        };
    },

    getSubCategoryElementSuccess: (data) => {
        return {
            type: actions.GET_SUBCATEGORY_ELEMENTS_SUCCESS,
            data,
        };
    },

    getSubCategoryElementErr: (err) => {
        return {
            type: actions.GET_SUBCATEGORY_ELEMENTS_ERR,
            err,
        };
    },

    getSubCategoryElementByIdBegin: () => {
        return {
            type: actions.GET_SUBCATEGORY_ELEMENTS_BY_ID_BEGIN,
        };
    },

    getSubCategoryElementByIdSuccess: (data) => {
        return {
            type: actions.GET_SUBCATEGORY_ELEMENTS_BY_ID_SUCCESS,
            data,
        };
    },

    getSubCategoryElementByIdErr: (err) => {
        return {
            type: actions.GET_SUBCATEGORY_ELEMENTS_BY_ID_ERR,
            err,
        };
    },

    updateSubCategoryElementBegin: () => {
        return {
            type: actions.UPDATE_SUBCATEGORY_ELEMENTS_BEGIN,
        };
    },

    updateSubCategoryElementErr: (err) => {
        return {
            type: actions.UPDATE_SUBCATEGORY_ELEMENTS_ERR,
            err,
        };
    },

    deleteSubCategoryElementBegin: () => {
        return {
            type: actions.DELETE_SUBCATEGORY_ELEMENTS_BEGIN,
        };
    },

    deleteSubCategoryElementErr: (err) => {
        return {
            type: actions.DELETE_SUBCATEGORY_ELEMENTS_ERR,
            err,
        };
    },

};

export default actions;
