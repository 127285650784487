import actions from "./actions";
// import staticData from '../../demoData/products.json';

const {
  SINGLE_PRODUCT_BEGIN,
  SINGLE_PRODUCT_SUCCESS,
  SINGLE_PRODUCT_ERR,

  FILTER_PRODUCT_BEGIN,
  FILTER_PRODUCT_SUCCESS,
  FILTER_PRODUCT_ERR,

  SORTING_PRODUCT_BEGIN,
  SORTING_PRODUCT_SUCCESS,
  SORTING_PRODUCT_ERR,

  ADD_PRODUCT_BEGIN,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_ER,

  FETCHING_PRODUCT_BEGIN,
  FETCHING_PRODUCT_SUCCESS,
  FETCHING_PRODUCT_ERR,

  FETCHING_SINGLE_PRODUCT_BEGIN,
  FETCHING_SINGLE_PRODUCT_SUCCESS,
  FETCHING_SINGLE_PRODUCT_ERR,
} = actions;

// const initialStateFilter = {
//     data: [],
//     loading: false,
//     error: null,
// };

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const AddProductReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ADD_PRODUCT_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case ADD_PRODUCT_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case ADD_PRODUCT_ER:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const productReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FILTER_PRODUCT_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case FILTER_PRODUCT_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case FILTER_PRODUCT_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    case SORTING_PRODUCT_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case SORTING_PRODUCT_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case SORTING_PRODUCT_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    case FETCHING_PRODUCT_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case FETCHING_PRODUCT_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case FETCHING_PRODUCT_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const SingleProductReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SINGLE_PRODUCT_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case SINGLE_PRODUCT_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case SINGLE_PRODUCT_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const FetchingSingleProductReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FETCHING_SINGLE_PRODUCT_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case FETCHING_SINGLE_PRODUCT_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case FETCHING_SINGLE_PRODUCT_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export {
  AddProductReducer,
  SingleProductReducer,
  productReducer,
  FetchingSingleProductReducer,
};
