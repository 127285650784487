import {
  // Uil500px,
  // UilAirplay,
  // UilArrowGrowth,
  // UilAt,
  // UilBagAlt,
  // UilBookAlt,
  // UilBookOpen,
  // UilBookReader,
  // UilCalendarAlt,
  // UilChartBar,
  // UilChat,
  // UilCheckSquare,
  // UilCircle,
  // UilClipboardAlt,
  // UilClock,
  // UilCompactDisc,
  UilCreateDashboard,
  // UilDatabase,
  // UilDocumentLayoutLeft,
  // UilEdit,
  // UilEnvelope,
  // UilExchange,
  // UilExclamationOctagon,
  // UilExpandArrowsAlt,
  // UilFile,
  // UilFileShieldAlt,
  // UilHeadphones,
  // UilIcons,
  // UilImages,
  // UilLayerGroup,
  // UilMap,
  // UilPresentation,
  // UilQuestionCircle,
  // UilSearch,
  // UilServer,
  // UilSetting,
  UilShoppingCart,
  // UilSquareFull,
  // UilTable,
  // UilUsdCircle,
  UilUsersAlt,
  // UilWindowSection,
} from "@iconscout/react-unicons";
import { Menu } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import UilEllipsisV from "@iconscout/react-unicons/icons/uil-ellipsis-v";
import propTypes from "prop-types";
// import { NavTitle } from "./Style";
// import versions from "../demoData/changelog.json";
// import {
//   changeDirectionMode,
//   changeLayoutMode,
//   changeMenuMode,
// } from "../redux/themeLayout/actionCreator";

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  // const dispatch = useDispatch();

  const path = "/";

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split("/");

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu
      ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
      : []
  );

  const onOpenChange = (keys) => {
    setOpenKeys(
      keys[keys.length - 1] !== "recharts"
        ? [keys.length && keys[keys.length - 1]]
        : keys
    );
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  // const changeLayout = (mode) => {
  //   dispatch(changeLayoutMode(mode));
  // };

  // const changeNavbar = (topMode) => {
  //   const html = document.querySelector("html");
  //   if (topMode) {
  //     html.classList.add("hexadash-topmenu");
  //   } else {
  //     html.classList.remove("hexadash-topmenu");
  //   }
  //   dispatch(changeMenuMode(topMode));
  // };

  // const changeLayoutDirection = (rtlMode) => {
  //   if (rtlMode) {
  //     const html = document.querySelector("html");
  //     html.setAttribute("dir", "rtl");
  //   } else {
  //     const html = document.querySelector("html");
  //     html.setAttribute("dir", "ltr");
  //   }
  //   dispatch(changeDirectionMode(rtlMode));
  // };

  // const darkmodeActivated = () => {
  //   document.body.classList.add("dark");
  // };

  // const darkmodeDiactivated = () => {
  //   document.body.classList.remove("dark");
  // };

  const items = [
    getItem(
      <NavLink onClick={toggleCollapsed} to={path}>
        {t("dashboard")}
      </NavLink>,
      "dashboard",
      !topMenu && (
        <NavLink className="menuItem-iocn" to={path}>
          <UilCreateDashboard />
        </NavLink>
      )
    ),

    getItem(t("Product"), "Product", !topMenu && <UilCreateDashboard />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}product/category`}>
          {t("Category")}
        </NavLink>,
        "Category",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}product/subcategory`}>
          {t("Subcategory")}
        </NavLink>,
        "Subcategory",
        null
      ),
      getItem(
        <NavLink
          onClick={toggleCollapsed}
          to={`${path}product/subCategoryElements`}
        >
          {t("SubCategory Elements")}
        </NavLink>,
        "SubCategory Elements",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}product`}>
          {t("Product")}
        </NavLink>,
        "Product",
        null
      ),
    ]),
    getItem(t("users"), "users", !topMenu && <UilUsersAlt />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`/users`}>
          {t("users")}
        </NavLink>,
        "users",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`/users/add-user`}>
          {t("add user")}
        </NavLink>,
        "add-user",
        null
      ),
    ]),
    getItem(t("Orders"), "Orders", !topMenu && <UilShoppingCart />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}product/orders`}>
          {t("All Orders")}
        </NavLink>,
        "All Orders",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}product/pendingOrders`}>
          {t("Pending Orders")}
        </NavLink>,
        "Pending Orders",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}product/shippedOrders`}>
          {t("Shipped Orders")}
        </NavLink>,
        "Shipped Orders",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}product/failedOrders`}>
          {t("Failed Orders")}
        </NavLink>,
        "Failed Orders",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}product/completeOrders`}>
          {t("Complete Orders")}
        </NavLink>,
        "Complete Orders",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}product/returnedOrders`}>
          {t("Returned Orders")}
        </NavLink>,
        "Returned Orders",
        null
      ),
    ]),
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? "inline" : "horizontal"}
      // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1
                  ? "home"
                  : mainPathSplit.length === 2
                  ? mainPathSplit[1]
                  : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={
        !topMenu
          ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
          : []
      }
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
