import actions from './actions';

const {
    ADD_SUBCATEGORY_BEGIN,
    ADD_SUBCATEGORY_SUCCESS,
    ADD_SUBCATEGORY_ERR,

    ALL_SUBCATEGORY_BEGIN,
    ALL_SUBCATEGORY_SUCCESS,
    ALL_SUBCATEGORY_ERR,

    GET_SUBCATEGORY_BY_CATEGORY_BEGIN,
    GET_SUBCATEGORY_BY_CATEGORY_SUCCESS,
    GET_SUBCATEGORY_BY_CATEGORY_ERR,

    UPDATE_SUBCATEGORY_BEGIN,
    UPDATE_SUBCATEGORY_SUCCESS,
    UPDATE_SUBCATEGORY_ERR,

    DELETE_SUBCATEGORY_BEGIN,
    DELETE_SUBCATEGORY_SUCCESS,
    DELETE_SUBCATEGORY_ERR,

} = actions;

const initialState = {
    data: [],
    loading: false,
    error: null,
};

const SubCategoryReducer = (state = initialState, action) => {
    const {type, data, err} = action;
    switch (type) {

        case ADD_SUBCATEGORY_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ADD_SUBCATEGORY_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case ADD_SUBCATEGORY_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case ALL_SUBCATEGORY_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ALL_SUBCATEGORY_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case ALL_SUBCATEGORY_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case UPDATE_SUBCATEGORY_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case UPDATE_SUBCATEGORY_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case UPDATE_SUBCATEGORY_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case DELETE_SUBCATEGORY_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case DELETE_SUBCATEGORY_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case DELETE_SUBCATEGORY_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};
const SubCategoryByIdReducer = (state = initialState, action) => {
    const {type, data, err} = action;
    switch (type) {

        case GET_SUBCATEGORY_BY_CATEGORY_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case GET_SUBCATEGORY_BY_CATEGORY_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case GET_SUBCATEGORY_BY_CATEGORY_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

export {
    SubCategoryReducer,
    SubCategoryByIdReducer,
};
