const actions = {

    ADD_SUBCATEGORY_BEGIN: 'ADD_SUBCATEGORY_BEGIN',
    ADD_SUBCATEGORY_SUCCESS: 'ADD_SUBCATEGORY_SUCCESS',
    ADD_SUBCATEGORY_ERR: 'ADD_SUBCATEGORY_ERR',

    ALL_SUBCATEGORY_BEGIN: 'ALL_SUBCATEGORY_BEGIN',
    ALL_SUBCATEGORY_SUCCESS: 'ALL_SUBCATEGORY_SUCCESS',
    ALL_SUBCATEGORY_ERR: 'ALL_SUBCATEGORY_ERR',

    GET_SUBCATEGORY_BY_CATEGORY_BEGIN: ' GET_SUBCATEGORY_BY_CATEGORY_BEGIN',
    GET_SUBCATEGORY_BY_CATEGORY_SUCCESS: ' GET_SUBCATEGORY_BY_CATEGORY_SUCCESS',
    GET_SUBCATEGORY_BY_CATEGORY_ERR: ' GET_SUBCATEGORY_BY_CATEGORY_ERR',

    UPDATE_SUBCATEGORY_BEGIN: 'UPDATE_SUBCATEGORY_BEGIN',
    UPDATE_SUBCATEGORY_SUCCESS: 'UPDATE_SUBCATEGORY_SUCCESS',
    UPDATE_SUBCATEGORY_ERR: 'UPDATE_SUBCATEGORY_ERR',

    DELETE_SUBCATEGORY_BEGIN: 'DELETE_SUBCATEGORY_BEGIN',
    DELETE_SUBCATEGORY_SUCCESS: 'DELETE_SUBCATEGORY_SUCCESS',
    DELETE_SUBCATEGORY_ERR: 'DELETE_SUBCATEGORY_ERR',

    addSubCategoryBegin: () => {
        return {
            type: actions.ADD_SUBCATEGORY_BEGIN,
        };
    },

    addSubCategorySuccess: () => {
        return {
            type: actions.ADD_SUBCATEGORY_SUCCESS,
        };
    },

    addSubCategoryErr: (err) => {
        return {
            type: actions.ADD_SUBCATEGORY_ERR,
            payload: err,
        };
    },

    allSubCategoryBegin: () => {
        return {
            type: actions.ALL_SUBCATEGORY_BEGIN,
        };
    },

    allSubCategorySuccess: (data) => {
        return {
            type: actions.ALL_SUBCATEGORY_SUCCESS,
            data,
        };
    },

    allSubCategoryErr: (err) => {
        return {
            type: actions.ALL_SUBCATEGORY_ERR,
            err,
        };
    },

    getSubCategoryByCategoryBegin: () => {
        return {
            type: actions.GET_SUBCATEGORY_BY_CATEGORY_BEGIN,
        };
    },

    getSubCategoryByCategorySuccess: (data) => {
        return {
            type: actions.GET_SUBCATEGORY_BY_CATEGORY_SUCCESS,
            data,
        };
    },

    getSubCategoryByCategoryErr: (err) => {
        return {
            type: actions.GET_SUBCATEGORY_BY_CATEGORY_ERR,
            err,
        };
    },

    updateSubCategoryBegin: () => {
        return {
            type: actions.UPDATE_SUBCATEGORY_BEGIN,
        };
    },

    updateSubCategorySuccess: (data) => {
        return {
            type: actions.UPDATE_SUBCATEGORY_SUCCESS,
            data,
        };
    },

    updateSubCategoryErr: (err) => {
        return {
            type: actions.UPDATE_SUBCATEGORY_ERR,
            err,
        };
    },

    deleteSubCategoryBegin: () => {
        return {
            type: actions.DELETE_SUBCATEGORY_BEGIN,
        };
    },

    deleteSubCategorySuccess: (data) => {
        return {
            type: actions.DELETE_SUBCATEGORY_SUCCESS,
            data,
        };
    },

    deleteSubCategoryErr: (err) => {
        return {
            type: actions.DELETE_SUBCATEGORY_ERR,
            err,
        };
    },
};

export default actions;
