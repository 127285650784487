import actions from './actions';

const {
    ADD_SUBCATEGORY_ELEMENTS_BEGIN,
    ADD_SUBCATEGORY_ELEMENTS_SUCCESS,
    ADD_SUBCATEGORY_ELEMENTS_ERR,

    ALL_SUBCATEGORY_ELEMENTS_BEGIN,
    ALL_SUBCATEGORY_ELEMENTS_SUCCESS,
    ALL_SUBCATEGORY_ELEMENTS_ERR,

    GET_SUBCATEGORY_ELEMENTS_BEGIN,
    GET_SUBCATEGORY_ELEMENTS_SUCCESS,
    GET_SUBCATEGORY_ELEMENTS_ERR,

    GET_SUBCATEGORY_ELEMENTS_BY_ID_BEGIN,
    GET_SUBCATEGORY_ELEMENTS_BY_ID_SUCCESS,
    GET_SUBCATEGORY_ELEMENTS_BY_ID_ERR,

    UPDATE_SUBCATEGORY_ELEMENTS_BEGIN,
    UPDATE_SUBCATEGORY_ELEMENTS_ERR,

} = actions;

const initialState = {
    data: [],
    loading: false,
    error: null,
};

const SubCategoryElementReducer = (state = initialState, action) => {
    const {type, data, err} = action;
    switch (type) {

        case ADD_SUBCATEGORY_ELEMENTS_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ADD_SUBCATEGORY_ELEMENTS_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case ADD_SUBCATEGORY_ELEMENTS_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case ALL_SUBCATEGORY_ELEMENTS_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case ALL_SUBCATEGORY_ELEMENTS_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case ALL_SUBCATEGORY_ELEMENTS_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case GET_SUBCATEGORY_ELEMENTS_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case GET_SUBCATEGORY_ELEMENTS_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case GET_SUBCATEGORY_ELEMENTS_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        case UPDATE_SUBCATEGORY_ELEMENTS_BEGIN:
            return {
                ...initialState,
                loading: true,
            };

        case UPDATE_SUBCATEGORY_ELEMENTS_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};


const GetSubCategoryIdElementReducer = (state = initialState, action) => {
    const {type, data, err} = action;
    switch (type) {

        case GET_SUBCATEGORY_ELEMENTS_BY_ID_BEGIN:
            return {
                ...initialState,
                loading: true,
            };
        case GET_SUBCATEGORY_ELEMENTS_BY_ID_SUCCESS:
            return {
                ...initialState,
                data,
                loading: false,
            };
        case GET_SUBCATEGORY_ELEMENTS_BY_ID_ERR:
            return {
                ...initialState,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

export {
    SubCategoryElementReducer,
    GetSubCategoryIdElementReducer,
};
