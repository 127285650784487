import axios from "axios";
import { getItem } from "../../utility/localStorageControl";

const API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}/`;

const authHeader = () => ({
  Authorization: `Bearer ${getItem("access_token")}`,
});

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${getItem("access_token")}`,
    "Content-Type": "application/json",
    enctype: "multipart/form-data",
  },
});

const navigateToLogin = () => {
  window.location.href = "/login";
};

client.interceptors.response.use(
  (response) => response, // Simply return the response if it's successful
  (error) => {
    if (error.response) {
      if (error.response.status === 403) {
        // Handle 403 Forbidden error
        console.error(
          "Access forbidden: You don't have permission to access this resource."
        );
        navigateToLogin();

        // You could add custom logic here, like redirecting the user or showing an alert
      } else if (error.response.status === 401) {
        // Handle 401 Unauthorized error
        console.error("Unauthorized: Please log in again.");
        // Optionally trigger a logout or redirect to login
        navigateToLogin();
      }
    }
    return Promise.reject(error); // Reject the error so the caller can handle it
  }
);

class DataService {
  static get(path = "") {
    return client({
      method: "GET",
      url: path,
      headers: { ...authHeader() },
    });
  }

  static post(path = "", data = {}, optionalHeader = {}) {
    return client({
      method: "POST",
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static patch(path = "", data = {}) {
    return client({
      method: "PATCH",
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static put(path = "", data = {}) {
    return client({
      method: "PUT",
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static delete(path = "", data = {}) {
    return client({
      method: "DELETE",
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use((config) => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = {
    ...headers,
    Authorization: `Bearer ${getItem("access_token")}`,
  };
  return requestConfig;
});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return error.response;
  }
);
export { DataService };
