const actions = {

    ADD_CATEGORY_BEGIN: 'ADD_CATEGORY_BEGIN',
    ADD_CATEGORY_SUCCESS: 'ADD_CATEGORY_SUCCESS',
    ADD_CATEGORY_ERR: 'ADD_CATEGORY_ERR',

    ALL_CATEGORY_BEGIN: 'ALL_CATEGORY_BEGIN',
    ALL_CATEGORY_SUCCESS: 'ALL_CATEGORY_SUCCESS',
    ALL_CATEGORY_ERR: 'ALL_CATEGORY_ERR',

    UPDATE_CATEGORY_BEGIN: 'UPDATE_CATEGORY_BEGIN',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_ERR: 'UPDATE_CATEGORY_ERR',

    DELETE_CATEGORY_BEGIN: 'DELETE_CATEGORY_BEGIN',
    DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
    DELETE_CATEGORY_ERR: 'DELETE_CATEGORY_ERR',


    addCategoryBegin: () => {
        return {
            type: actions.ADD_CATEGORY_BEGIN,
        };
    },

    addCategorySuccess: () => {
        return {
            type: actions.ADD_CATEGORY_SUCCESS,
        };
    },

    addCategoryErr: (err) => {
        return {
            type: actions.ADD_CATEGORY_ERR,
            payload: err,
        };
    },


    allCategoryBegin: () => {
        return {
            type: actions.ALL_CATEGORY_BEGIN,
        };
    },

    allCategorySuccess: (data) => {
        return {
            type: actions.ALL_CATEGORY_SUCCESS,
            data,
        };
    },

    allCategoryErr: (err) => {
        return {
            type: actions.ALL_CATEGORY_ERR,
            err,
        };
    },

    updateCategoryBegin: () => {
        return {
            type: actions.UPDATE_CATEGORY_BEGIN,
        };
    },

    updateCategorySuccess: (data) => {
        return {
            type: actions.UPDATE_CATEGORY_SUCCESS,
            data,
        };
    },

    updateCategoryErr: (err) => {
        return {
            type: actions.UPDATE_CATEGORY_ERR,
            err,
        };
    },

    deleteCategoryBegin: () => {
        return {
            type: actions.DELETE_CATEGORY_BEGIN,
        };
    },

    deleteCategorySuccess: (data) => {
        return {
            type: actions.DELETE_CATEGORY_SUCCESS,
            data,
        };
    },

    deleteCategoryErr: (err) => {
        return {
            type: actions.DELETE_CATEGORY_ERR,
            err,
        };
    },

};

export default actions;
