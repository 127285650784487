import { combineReducers } from "redux";
import themeUsersReducer from "./themeUsers/reducers";
import { readMessageReducer } from "./message/reducers";
import { readNotificationReducer } from "./notification/reducers";
import authReducer from "./authentication/reducers";
import ChangeLayoutMode from "./themeLayout/reducers";
import { teamReducer } from "./team/reducers";
import { userReducer, userGroupReducer } from "./users/reducers";
import { sellersReducer } from "./sellers/reducers";
import { headerSearchReducer } from "./headerSearch/reducers";
import {
  ordersReducer,
  singleOrderReducer,
  ordersByStatusReducer,
  singleOrderByStatusReducer,
} from "./orders/reducers";
import galleryReducer from "./gallary/reducers";
import { emailReducer, SingleEmailReducer } from "./email/reducers";
import {
  AddProductReducer,
  FetchingSingleProductReducer,
  productReducer,
  SingleProductReducer,
} from "./product/reducers";
import {
  chatReducer,
  SingleChatReducer,
  groupChatReducer,
  SingleChatGroupReducer,
} from "./chat/reducers";
import { projectReducer, SingleProjectReducer } from "./project/reducers";
import {
  axiosCrudReducer,
  axiosSingleCrudReducer,
} from "./crud/axios/reducers";
import cartData from "./cart/reducers";
import Todo from "./todo/reducers";
import Note from "./note/reducers";
import Task from "./task/reducers";
import kanbanBoardReducer from "./kanban/reducers";
import Contact from "./contact/reducers";
import Profile from "./profile/reducers";
import Calender from "./calendar/reducers";
import FileManager from "./fileManager/reducers";
import tickets from "./supportTickets/reducers";
import jobs from "./jobs/reducers";
import dataTable from "./data-filter/reducers";

import { CategoryReducer } from "./category/reducers";
import {
  SubCategoryByIdReducer,
  SubCategoryReducer,
} from "./subCategory/reducers";
import {
  GetSubCategoryIdElementReducer,
  SubCategoryElementReducer,
} from "./subCategoryElement/reducers";

const rootReducers = combineReducers({
  themeUsers: themeUsersReducer,
  headerSearchData: headerSearchReducer,
  message: readMessageReducer,
  notification: readNotificationReducer,
  sellers: sellersReducer,
  users: userReducer,
  userGroup: userGroupReducer,
  team: teamReducer,
  auth: authReducer,
  gallery: galleryReducer,
  email: emailReducer,
  emailSingle: SingleEmailReducer,
  product: SingleProductReducer,
  chatSingle: SingleChatReducer,
  chatSingleGroup: SingleChatGroupReducer,
  chat: chatReducer,
  groupChat: groupChatReducer,
  projects: projectReducer,
  project: SingleProjectReducer,
  ChangeLayoutMode,
  cart: cartData,
  Todo,
  Note,
  AxiosCrud: axiosCrudReducer,
  Task,
  KanbanBoard: kanbanBoardReducer,
  Contact,
  Profile,
  Calender,
  FileManager,
  tickets,
  jobs,
  dataTable,
  SingleAxiosCrud: axiosSingleCrudReducer,

  Category: CategoryReducer,
  SubCategory: SubCategoryReducer,
  SubCategoryById: SubCategoryByIdReducer,
  SubCategoryElement: SubCategoryElementReducer,
  SubCategoryElementById: GetSubCategoryIdElementReducer,

  AddProduct: AddProductReducer,
  Product: productReducer,
  FetchingSingleProduct: FetchingSingleProductReducer,
  orders: ordersReducer,
  singleOrder: singleOrderReducer,
  ordersByStatus: ordersByStatusReducer,
  singleOrderByStatus: singleOrderByStatusReducer,
});

export default rootReducers;
